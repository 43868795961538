import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { errorEvents } from './errorEvents';
import { useIntl } from 'react-intl';

export const ErrorToast = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const intl = useIntl();
    useEffect(() => {
        return errorEvents.subscribe((msg) => {
            setMessage(msg);
            setOpen(true);
        });
    }, []);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert severity="error" onClose={() => setOpen(false)}>
                {message && intl.formatMessage({ id: message })}
            </Alert>
        </Snackbar>
    );
};
