import Clarity from '@microsoft/clarity';
import { GaAction, GaCategory, logGAEvent } from '../../config/googleAnalytics';
import { isDevEnv } from '../environment';

type LogAnalyticsEventParams = {
    category: GaCategory;
    action: GaAction;
    label: string;
    eventName: string;
};
export function logAnalyticsEvent({
    category,
    action,
    label,
    eventName,
}: LogAnalyticsEventParams) {
    if (!isDevEnv()) {
        logGAEvent(category, action, label);
        Clarity.event(eventName);
    }
}
