import { FileWithIdAndStatus } from '../../hooks/useFile';
import { UploadStatus } from './types';

export const fileToAwaitingUploadFile = (file: File): FileWithIdAndStatus => {
    const fileWithIdAndStatus = file as FileWithIdAndStatus;
    fileWithIdAndStatus.uploadStatus = UploadStatus.pending;
    fileWithIdAndStatus._id = null;
    return fileWithIdAndStatus;
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
    try {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    } catch (e) {
        throw new Error('Problem with file parsing');
    }
};
