import { EncryptedObjectIdString } from '../types/common';
import { ProjectDto } from '../types/dto/project';
import { buildPath, createResourceService } from './resourceService';

export const getProjects = createResourceService<
    { encryptedSimpleFormId: EncryptedObjectIdString },
    ProjectDto[]
>({
    getPath: ({ encryptedSimpleFormId }) =>
        buildPath(encryptedSimpleFormId, 'project'),
});
