// import ar from './translations/messages.ar_AE.json';
// import bg from './translations/messages.bg_BG.json';
// import cs from './translations/messages.cs_CZ.json';
// import de from './translations/messages.de_DE.json';
import en from './translations/messages.en.json';
// import es from './translations/messages.es.json';
// import fr from './translations/messages.fr.json';
// import he from './translations/messages.he_IL.json';
// import hr from './translations/messages.hr_HR.json';
// import hu from './translations/messages.hu_HU.json';
import pl from './translations/messages.pl.json';
// import pt from './translations/messages.pt_PT.json';
// import ro from './translations/messages.ro.json';
// import ru from './translations/messages.ru_UA.json';
// import sk from './translations/messages.sk_SK.json';
// import tr from './translations/messages.tr_TR.json';
// import uk from './translations/messages.uk_UA.json';
import { ISO6391LanguageCodeOption, TranslationsObject } from './types';

export function getUnescapedTranslationObjectByLocale(
    locale: ISO6391LanguageCodeOption,
): TranslationsObject {
    switch (locale) {
        case 'en':
            return en;
        // case 'ar':
        //     return ar;
        // case 'bg':
        //     return bg;
        // case 'hr':
        //     return hr;
        // case 'cs':
        //     return cs;
        // case 'fr':
        //     return fr;
        // case 'de':
        //     return de;
        // case 'he':
        //     return he;
        // case 'hu':
        //     return hu;
        case 'pl':
            return pl;
        // case 'pt':
        //     return pt;
        // case 'ro':
        //     return ro;
        // case 'ru':
        //     return ru;
        // case 'sk':
        //     return sk;
        // case 'es':
        //     return es;
        // case 'tr':
        //     return tr;
        // case 'uk':
        //     return uk;
        default:
            return en;
    }
}
