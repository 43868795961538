import { getProjects } from '../services/projectsService';
import { EncryptedObjectIdString } from '../types/common';
import { ProjectDto } from '../types/dto/project';
import { useResource } from './useResource';

const projectsByEncryptedSimpleFormIdMap = new Map<
    EncryptedObjectIdString,
    ProjectDto[]
>();

export const useProjects = () => {
    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
        },
        ProjectDto[]
    >({
        fetchFn: getProjects,
        cacheMap: projectsByEncryptedSimpleFormIdMap,
        getCacheKey: ({ encryptedSimpleFormId }) => encryptedSimpleFormId,
    });

    return { fetchProjects: fetchResource };
};
