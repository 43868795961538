import { EncryptedObjectIdString } from '../types/common';
import { SimpleFormDto } from '../types/dto/simpleForm';
import { buildPath, createResourceService } from './resourceService';

export const getSimpleForm = createResourceService<
    {
        encryptedSimpleFormId: EncryptedObjectIdString;
    },
    SimpleFormDto
>({
    getPath: ({ encryptedSimpleFormId }) => buildPath(encryptedSimpleFormId),
});
