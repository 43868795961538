import axios from 'axios';
import { getApiUrl } from '../utils/apiUrl';

export function getAuthHeader(token: string) {
    return { Authorization: `Bearer ${token}` };
}
export const authorizedApiClient = axios.create({
    baseURL: `${getApiUrl()}/v2`,
    headers: {
        'Content-Type': 'application/json',
        'hustro-client': 'simpleForm',
        'hustro-client-version': '2.91.0',
    },
});
export const unauthorizedApiClient = axios.create({
    baseURL: `${getApiUrl()}/unauthorized/`,
    headers: {
        'Content-Type': 'application/json',
    },
});
export const simpleFormApiClient = axios.create({
    baseURL: `${getApiUrl()}/unauthorized/simpleForm`,
    headers: {
        'Content-Type': 'application/json',
    },
});
