import { useEffect } from 'react';

interface FetchConfig<TParams, TResponse> {
    fetchFn: (params: TParams) => Promise<TResponse>;
    dependencies: (boolean | string | undefined)[];
    params: TParams | undefined;
    resourceName: string;
    onError?: (error: Error) => void;
    setData: (data: TResponse | null) => void;
    setIsLoading?: (loading: boolean) => void;
}

export const useFetchResource = <TParams, TResponse>({
    fetchFn,
    dependencies,
    params,
    onError,
    resourceName,
    setData,
    setIsLoading,
}: FetchConfig<TParams, TResponse>) => {
    useEffect(() => {
        const fetchAndSetResource = async () => {
            try {
                if (setIsLoading) {
                    setIsLoading(true);
                }

                if (!dependencies.every(Boolean) || !params) {
                    setData(null);
                    return;
                }

                const fetchedData = await fetchFn(params);
                setData(fetchedData);
            } catch (error) {
                const normalizedError =
                    error instanceof Error
                        ? error
                        : new Error(`Failed to fetch ${resourceName}`);

                if (onError) {
                    onError(normalizedError);
                }

                if (setIsLoading) {
                    setIsLoading(false);
                }
                // const errorEvent = new ErrorEvent('error', { error });
                // window.dispatchEvent(errorEvent);
            }
        };

        fetchAndSetResource();
    }, [
        dependencies,
        fetchFn,
        onError,
        params,
        resourceName,
        setData,
        setIsLoading,
    ]);
};
