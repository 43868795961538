import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { OrganizationDto } from '../types/dto/organization';
import { ApiClient, createResourceService } from './resourceService';

export const organizationService = {
    getOrganization: createResourceService<
        { encryptedClientId: EncryptedObjectIdString },
        OrganizationDto
    >({
        getPath: ({ encryptedClientId }) => `/client/${encryptedClientId}`,
        client: ApiClient.Unauthorized,
    }),

    getOrganizationLogoSignedUrl: createResourceService<
        {
            encryptedClientId: EncryptedObjectIdString;
            logoId: ObjectIdString;
        },
        string
    >({
        getPath: ({ encryptedClientId, logoId }) =>
            `/client/${encryptedClientId}/logo/${logoId}/download`,
        mapResponse: (response: { signedRequest: string }) =>
            response.signedRequest,
        client: ApiClient.Unauthorized,
    }),
};
