export enum SelectedLocationType {
    pin = 'pin',
    area = 'area',
}
export interface PositionOnMap {
    x: number;
    y: number;
}
export type Area = PositionOnMap[];

export interface IssueDto {
    title: string;
    description?: string;
    project: string;
    site: string;
    level: string;
    selectedLocationType: SelectedLocationType;
    positionOnMap?: PositionOnMap;
    targetAreas?: Area[];
}
