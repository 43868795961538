import { Auth0ProviderOptions } from '@auth0/auth0-react';
import { ENVIRONMENTS, getEnvironment } from '../utils/environment';

const auth0StagingDomain = 'hustro-staging.eu.auth0.com';
const auth0StagingAudience = `https://${auth0StagingDomain}/api/v2/`;
const auth0StagingClientId = 'yEatT5fFcjeSzQip0A0wnxEQIGOXnKdB';

const auth0ProdDomain = 'hustro.eu.auth0.com';
const auth0ProdAudience = `https://${auth0ProdDomain}/api/v2/`;
const auth0ProdClientId = 'NloWsPLabjdRjyS6b2RuX82HPOuygGel';

const auth0DevClientId = '3EnAM1pijebgIJvRGqxacMHCHFDuyyIH';
const auth0DevDomain = 'hustro-asub.eu.auth0.com';
const auth0DevAudience = `https://${auth0DevDomain}/api/v2/`;

export const getAuth0Config = (): Auth0ProviderOptions => {
    // Zapisz aktualną ścieżkę
    const currentPath = window.location.pathname;

    // Zakoduj state z informacją o ścieżce
    const state = btoa(
        JSON.stringify({
            formPath: currentPath,
            timestamp: Date.now(),
        }),
    );
    const config = {
        domain: '',
        clientId: '',
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: '',
            state,
            // Dodaj returnTo do parametrów autoryzacji
            returnTo: `${window.location.origin}${currentPath}`,
        },
    };
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.DEVELOPMENT:
            return {
                ...config,
                domain: auth0DevDomain,
                clientId: auth0DevClientId,
                authorizationParams: {
                    ...config.authorizationParams,
                    audience: auth0DevAudience,
                },
            };
        case ENVIRONMENTS.STAGING:
            return {
                ...config,
                domain: auth0StagingDomain,
                clientId: auth0StagingClientId,
                authorizationParams: {
                    ...config.authorizationParams,
                    audience: auth0StagingAudience,
                },
            };
        case ENVIRONMENTS.PRODUCTION:
            return {
                ...config,
                domain: auth0ProdDomain,
                clientId: auth0ProdClientId,
                authorizationParams: {
                    ...config.authorizationParams,
                    redirect_uri: window.location.origin,
                    audience: auth0ProdAudience,
                },
            };
    }
};
