import { ENVIRONMENTS, getEnvironment } from './environment';

export const getApiUrl = (): string => {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.DEVELOPMENT:
            return 'http://localhost:8093';
        case ENVIRONMENTS.STAGING:
            return 'https://stage.o.hustroapp.com/api';
        case ENVIRONMENTS.PRODUCTION:
            return 'https://app.hustro.com/api';
    }
};
