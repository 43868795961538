import '@fontsource/archivo/300.css';
import '@fontsource/archivo/400.css';
import '@fontsource/archivo/500.css';
import '@fontsource/archivo/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { initClarity } from './config/clarity.ts';
import { initSentry } from './config/sentry.ts';
import './index.css';

initSentry();
initClarity();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
