import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from '../config/googleAnalytics';

export const WithGoogleAnalytics = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const location = useLocation();

    useEffect(() => {
        initGA();
    }, []);

    useEffect(() => {
        logPageView();
    }, [location]);

    return children;
};
