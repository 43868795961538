import { isSupportedLocale } from './isSupportedLocale';
import { ISO6391LanguageCode } from './types';

export function getLocaleFromBrowser(): ISO6391LanguageCode {
    const currentBrowserLocale: string | undefined = navigator.language.slice(
        0,
        2,
    );
    if (!currentBrowserLocale) {
        return ISO6391LanguageCode.en;
    }
    if (isSupportedLocale(currentBrowserLocale)) {
        return currentBrowserLocale;
    }
    return ISO6391LanguageCode.en;
}
