import Clarity from '@microsoft/clarity';
import { ObjectIdString } from '../../types/common';
import { identifyUserForGA } from '../../config/googleAnalytics';
import { isDevEnv } from '../environment';
export enum PageName {
    issue = 'issue',
    fileUpload = 'file-upload',
    personalDataOnOrganization = 'organization-personal-data',
    personalData = 'all-personal-data',
}
export function identifyUserAndPage(
    userId: ObjectIdString,
    pageName: PageName,
    userEmail: string,
) {
    if (!isDevEnv()) {
        identifyUserForGA(userId, { userEmail });
        Clarity.identify(userId, undefined, pageName, userEmail);
    }
}
