import Clarity from '@microsoft/clarity';
import { ENVIRONMENTS, getEnvironment, isDevEnv } from '../utils/environment';
const productionProjectId = 'oxhigmdp5m';
const stagingProjectId = 'oxiotzcdqo';
function getProjectId() {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.DEVELOPMENT:
            return '';
        case ENVIRONMENTS.STAGING:
            return stagingProjectId;
        case ENVIRONMENTS.PRODUCTION:
            return productionProjectId;
    }
}

export function initClarity() {
    if (!isDevEnv()) {
        Clarity.init(getProjectId());
        Clarity.setTag('environment', getEnvironment());
    }
}
