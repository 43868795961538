import { createContext, ReactNode } from 'react';
import { SumoLogicLogger } from './sumologicLogger';
import { LoggerOptions } from './types';

interface LoggerContextValue {
    logger: SumoLogicLogger;
}

export const LoggerContext = createContext<LoggerContextValue | null>(null);

interface LoggerProviderProps {
    children: ReactNode;
    collectorUrl: string;
    options?: LoggerOptions;
}

export const LoggerProvider: React.FC<LoggerProviderProps> = ({
    children,
    collectorUrl,
    options,
}) => {
    const loggerValue: LoggerContextValue = {
        logger: new SumoLogicLogger(collectorUrl, options),
    };

    return (
        <LoggerContext.Provider value={loggerValue}>
            {children}
        </LoggerContext.Provider>
    );
};
