import { useRef, useCallback } from 'react';

export type ClickHandler = (event: React.MouseEvent | React.TouchEvent) => void;

interface UseClickWithoutDragOptions {
    onClick: ClickHandler;
    threshold?: number;
}

interface ClickWithoutDragHandlers {
    onMouseDown: (e: React.MouseEvent) => void;
    onMouseMove: (e: React.MouseEvent) => void;
    onMouseUp: (e: React.MouseEvent) => void;
    onTouchStart: (e: React.TouchEvent) => void;
    onTouchMove: (e: React.TouchEvent) => void;
    onTouchEnd: (e: React.TouchEvent) => void;
}

export const useClickWithoutDrag = ({
    onClick,
    threshold = 5,
}: UseClickWithoutDragOptions): ClickWithoutDragHandlers => {
    const isDragging = useRef(false);
    const startX = useRef(0);
    const startY = useRef(0);

    const handleStart = useCallback(
        (e: React.MouseEvent | React.TouchEvent) => {
            const point = 'touches' in e ? e.touches[0] : e;
            startX.current = point.clientX;
            startY.current = point.clientY;
            isDragging.current = false;
        },
        [],
    );

    const handleMove = useCallback(
        (e: React.MouseEvent | React.TouchEvent) => {
            if ('buttons' in e && e.buttons !== 1) return;

            const point = 'touches' in e ? e.touches[0] : e;
            const diffX = Math.abs(point.clientX - startX.current);
            const diffY = Math.abs(point.clientY - startY.current);

            if (diffX > threshold || diffY > threshold) {
                isDragging.current = true;
            }
        },
        [threshold],
    );

    const handleEnd = useCallback(
        (e: React.MouseEvent | React.TouchEvent) => {
            if (!isDragging.current) {
                onClick(e);
            }
            isDragging.current = false;
        },
        [onClick],
    );

    return {
        onMouseDown: handleStart,
        onMouseMove: handleMove,
        onMouseUp: handleEnd,
        onTouchStart: handleStart,
        onTouchMove: handleMove,
        onTouchEnd: handleEnd,
    };
};
