import { Box, Button, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { dataURLtoFile, fileToAwaitingUploadFile } from './utils';
import { FileWithIdAndStatus } from '../../hooks/useFile';

interface WebCameraProps {
    onClose: () => void;
    setFiles: React.Dispatch<React.SetStateAction<FileWithIdAndStatus[]>>; // typ any[] należy zastąpić właściwym typem plików
    files: FileWithIdAndStatus[]; // typ any[] należy zastąpić właściwym typem plików
}

export const WebCamera: React.FC<WebCameraProps> = ({
    onClose,
    setFiles,
    files,
}) => {
    const webcamRef = useRef<Webcam>(null);
    const [cameraError, setCameraError] = useState<string | null>(null);

    const handleCapture = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                const newFile = dataURLtoFile(imageSrc, 'captured.jpg');
                setFiles([...files, fileToAwaitingUploadFile(newFile)]);
            } else {
                console.error('No image source');
            }
            onClose();
        }
    };
    return (
        <Box
            sx={{
                position: 'relative',
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: 'user',
                }}
                onUserMediaError={(error) => {
                    console.error('Camera error:', error);
                    setCameraError('Nie udało się uzyskać dostępu do kamery');
                }}
                style={{
                    width: '100%',
                    maxWidth: '600px',
                    borderRadius: '8px',
                }}
            />

            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleCapture}
                    disabled={!!cameraError}
                >
                    Zrób zdjęcie
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Zamknij kamerę
                </Button>
            </Box>

            {cameraError && (
                <Typography color="error" sx={{ mt: 1 }}>
                    {cameraError}
                </Typography>
            )}
        </Box>
    );
};
