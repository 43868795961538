import { useEffect, useRef } from 'react';

export function useDidMount(callback: () => void): void {
    const mounted = useRef<boolean>(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            callback();
        }
    });
}
