import { DocumentTypes } from '../../services/fileService';
import { ObjectIdString } from '../../types/common';

export enum UploadResult {
    success = 'uploadConfirmation',
    failed = 'uploadFailure',
}

export interface UploadOutDto {
    signedRequest: {
        url: string;
        fields: {
            acl: string;
            bucket: string;
            'X-Amz-Algorithm': string;
            'X-Amz-Credential': string;
            'X-Amz-Date': string;
            key: string;
            Policy: string;
            'X-Amz-Signature': string;
        };
    };
    document: {
        _id: ObjectIdString;
        createdAt: string;
        modifiedAt: string;
        createdBy: string;
        modifiedBy: string;
        title: string;
        description: string;
        type: 'FILE';
        deleted: boolean;
        data: {
            documentType: 'FILE';
            name: string;
            extension: string;
            type: string;
            key: string;
            url: string;
            bucketName: string;
            uploadStatus: UploadStatus;
            isDrawn: boolean;
        };
    };
}
export type DocumentModel = {
    _id?: string;
    localId: number;
    title: string;
    type: string;
    // data can come as null from backend
    data: FileInfo | null;
    // TODO: PT-3773
    description?: string | null;
    deleted: boolean;
    modifiedAt: string;
    localData?: string;
    // syncStatus: SyncStatus;
    // drawingSyncStatus: SyncStatus;
    issueId: ObjectIdString;
};

type FileInfo = {
    name: string;
    type: string;
    documentType: DocumentTypes;
    key: string;
    extension: string;
    url: string;
    bucketName: string;
    uploadStatus: UploadStatus;
    isDrawn: boolean;
};
export enum UploadStatus {
    pending = 'PENDING',
    success = 'SUCCESS',
    failed = 'FAILED',
    // for situations like file too big
    // or invalid extension
    failedPerm = 'FAILED_PERMANENTLY',
}
