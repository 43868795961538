import ReactGA from 'react-ga4';
import { getEnvironment, ENVIRONMENTS, isDevEnv } from '../utils/environment';
import { ObjectIdString } from '../types/common';
const stagingMeasurementId = 'G-7NY9D3J3C2';
const productionMeasurementId = 'G-DRQ44CQ464';
function getMeasurementId() {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENTS.DEVELOPMENT:
            return '';
        case ENVIRONMENTS.STAGING:
            return stagingMeasurementId;
        case ENVIRONMENTS.PRODUCTION:
            return productionMeasurementId;
    }
}

export const initGA = () => {
    if (!isDevEnv()) {
        ReactGA.initialize(getMeasurementId());
    }
};

// Funkcja do śledzenia odsłon strony
export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export enum GaCategory {
    Button = 'Button',
}

export enum GaAction {
    Click = 'Click',
}

// Funkcja do śledzenia zdarzeń
export const logGAEvent = (
    category: GaCategory,
    action: GaAction,
    label: string,
) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

// Funkcja do ustawiania User ID
export const identifyUserForGA = (
    userId: ObjectIdString,
    userProperties = {},
) => {
    // Ustawienie User ID
    ReactGA.set({ userId: userId });

    // Ustawienie właściwości użytkownika
    ReactGA.set({
        user_properties: {
            ...userProperties,
        },
    });
};
