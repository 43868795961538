import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    extractEncryptedSimpleFormIdFromPath,
    extractEncryptedSimpleFormIdFromQuery,
    getSimpleFormId,
    isEncryptedObjectIdString,
    setSimpleFormId,
} from '../utils/simpleFormId';

export function RedirectToSimpleFormId() {
    const navigate = useNavigate();

    useEffect(() => {
        const existingEncryptedSimpleFormIdInPath: string | null =
            extractEncryptedSimpleFormIdFromPath();
        if (isEncryptedObjectIdString(existingEncryptedSimpleFormIdInPath)) {
            return setSimpleFormId(existingEncryptedSimpleFormIdInPath);
        }
        const encryptedSimpleFormIdInQuery =
            extractEncryptedSimpleFormIdFromQuery();
        if (isEncryptedObjectIdString(encryptedSimpleFormIdInQuery)) {
            setSimpleFormId(encryptedSimpleFormIdInQuery);
            return navigate(`/${encryptedSimpleFormIdInQuery}`, {
                replace: true,
            });
        }

        const encryptedSimpleFormIdToRedirectUserTo = getSimpleFormId();
        if (encryptedSimpleFormIdToRedirectUserTo) {
            return navigate(`/${encryptedSimpleFormIdToRedirectUserTo}`, {
                replace: true,
            });
        }

        navigate('/formCode');
    }, [navigate]);

    return null;
}
