import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getLocationMapSignedUrl } from '../services/locationMapService';
import { ObjectIdString } from '../types/common';

const signedUrlsByIdMap = new Map<string, string>();

export const useSignedUrls = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { encryptedSimpleFormId } = useParams();

    const fetchSignedUrl = useCallback(
        async (
            levelId: ObjectIdString,
            documentationId: ObjectIdString,
            versionId: ObjectIdString,
        ): Promise<string> => {
            if (!encryptedSimpleFormId) {
                throw new Error('No simple form hash detected');
            }
            // const cachedResult = signedUrlsByIdMap.get(documentationId);
            // if (cachedResult) {
            //     console.log('Returning cached signedUrl');
            //     return cachedResult;
            // }

            const signedUrl = await getLocationMapSignedUrl(
                await getAccessTokenSilently(),
                { encryptedSimpleFormId, levelId, documentationId, versionId },
            );
            signedUrlsByIdMap.set(documentationId, signedUrl);
            return signedUrl;
        },
        [getAccessTokenSilently, encryptedSimpleFormId],
    );

    return { fetchSignedUrl };
};
