import { Container, Paper } from '@mui/material';

export const PatternedBackground = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <Paper
            square
            elevation={0}
            sx={{
                background: `
                    radial-gradient(circle at 10% 20%, rgba(95, 95, 196, 0.18) 0%, transparent 30%),
                    radial-gradient(circle at 85% 30%, rgba(40, 53, 147, 0.15) 0%, transparent 35%),
                    radial-gradient(circle at 30% 65%, rgba(95, 95, 196, 0.16) 0%, transparent 30%),
                    radial-gradient(circle at 70% 80%, rgba(40, 53, 147, 0.13) 0%, transparent 35%),
                    radial-gradient(circle at 50% 50%, rgba(255, 250, 245, 0.1) 0%, transparent 50%)
                `,
                backdropFilter: 'blur(90px)',
                minHeight: '100vh',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#ffffff',
            }}
        >
            <Container sx={{ py: 3 }}>{children}</Container>
        </Paper>
    );
};
