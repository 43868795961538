import { Box } from '@mui/material';
import { IssueForm } from '../components/issueForm';
import { AuthSkeleton } from '../components/skeleton';
import { FormStepper } from '../components/stepper';
import { useMe } from '../hooks/useMe';
import {
    identifyUserAndPage,
    PageName,
} from '../utils/locale/identifyUserAndPage';

export const IssueFormView = () => {
    const { me } = useMe();

    if (!me) {
        return <AuthSkeleton sx={{ mt: 6, mx: 'auto' }} />;
    }
    identifyUserAndPage(me._id, PageName.issue, me.email);
    return (
        <Box>
            <FormStepper activeStepIndex={0} />
            <IssueForm />
        </Box>
    );
};
