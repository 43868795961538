import { getSimpleForm } from '../services/simpleFormService';
import { EncryptedObjectIdString } from '../types/common';
import { SimpleFormDto } from '../types/dto/simpleForm';
import { useResource } from './useResource';

const simpleFormByEncryptedIdMap = new Map<
    EncryptedObjectIdString,
    SimpleFormDto
>();

export const useSimpleForm = () => {
    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
        },
        SimpleFormDto
    >({
        fetchFn: getSimpleForm,
        cacheMap: simpleFormByEncryptedIdMap,
        getCacheKey: ({ encryptedSimpleFormId }) => encryptedSimpleFormId,
    });

    return { fetchSimpleForm: fetchResource };
};
