import React, { useState } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { MapWithPin } from './map';
import { ObjectIdString } from '../../types/common';

export type MapTransformWrapperProps = {
    levelId: ObjectIdString;
    documentationId: ObjectIdString;
    versionId: ObjectIdString;
    pinPositionX: number;
    pinPositionY: number;
    setPinPosition: (x: number, y: number) => void;
};

export const MapTransformWrapper: React.FC<MapTransformWrapperProps> = ({
    levelId,
    documentationId,
    versionId,
    pinPositionX,
    pinPositionY,
    setPinPosition,
}) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const initialScale = 1;
    const [scale, setScale] = useState<number>(initialScale);

    return (
        <div
            ref={containerRef}
            style={{ width: '100%', height: '100%', margin: 'auto' }}
        >
            <TransformWrapper
                initialScale={initialScale}
                initialPositionX={0}
                initialPositionY={0}
                onTransformed={({ instance }) => {
                    setScale(instance.transformState.scale);
                }}
            >
                <MapWithPin
                    pinPositionX={pinPositionX}
                    pinPositionY={pinPositionY}
                    setPinPosition={setPinPosition}
                    containerRef={containerRef}
                    levelId={levelId}
                    documentationId={documentationId}
                    versionId={versionId}
                    scale={scale}
                />
            </TransformWrapper>
        </div>
    );
};
