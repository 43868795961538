import { getSites } from '../services/siteService';
import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { SiteDto } from '../types/dto/site';
import { useResource } from './useResource';

const sitesByProjectMap = new Map<string, SiteDto[]>();

export const useSites = () => {
    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
            projectId: ObjectIdString;
        },
        SiteDto[]
    >({
        fetchFn: getSites,
        cacheMap: sitesByProjectMap,
        getCacheKey: ({ projectId }) => projectId,
    });

    return { fetchSites: fetchResource };
};
