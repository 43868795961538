import { useAuth0 } from '@auth0/auth0-react';
import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocaleFromBrowser } from '../utils/locale/getDefaultLocale';
import { ISO6391LanguageCode } from '../utils/locale/types';
import { useIntl } from 'react-intl';
export const TopBar: React.FC = () => {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null,
    );

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleLogout = () => {
        const encryptedSimpleFormId = localStorage.getItem(
            'encryptedSimpleFormId',
        );
        localStorage.clear();
        if (encryptedSimpleFormId) {
            localStorage.setItem(
                'encryptedSimpleFormId',
                encryptedSimpleFormId,
            );
        }
        logout();
        setAnchorElUser(null);
    };
    const redirectToMyProfile = () => {
        setAnchorElUser(null);
        navigate('/user');
    };
    const redirectToHelp = () => {
        setAnchorElUser(null);
        const locale = getLocaleFromBrowser();
        if (locale === ISO6391LanguageCode.pl) {
            return (window.location.href =
                'https://www.hustro.com/pl/skontaktuj-sie-z-nami/');
        }
        return (window.location.href = 'https://www.hustro.com/contact/');
    };

    const { user } = useAuth0();
    const intl = useIntl();
    return (
        <AppBar>
            <Box>
                <Box display="flex" justifyContent="flex-end">
                    <Tooltip
                        title={intl.formatMessage({ id: 'open_user_profile' })}
                    >
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                            <Avatar alt={'user profile'} src={user?.picture} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem key={'my-profile'} onClick={redirectToMyProfile}>
                        <Typography textAlign="center">
                            {intl.formatMessage({ id: 'my-profile' })}
                        </Typography>
                    </MenuItem>
                    <MenuItem key={'help'} onClick={redirectToHelp}>
                        <Typography textAlign="center">
                            {intl.formatMessage({ id: 'help' })}
                        </Typography>
                    </MenuItem>
                    <MenuItem key={'logout'} onClick={handleLogout}>
                        <Typography textAlign="center">
                            {intl.formatMessage({ id: 'logout' })}
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </AppBar>
    );
};
