import React, { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useDidMount } from '../hooks/useDidMount';
import { englishTranslations } from '../utils/locale/englishTranslations';
import { getLocaleFromBrowser } from '../utils/locale/getDefaultLocale';
import { getEscapedTranslations } from '../utils/locale/getEscapedTranslations';
import { setHtmlRootLangAttribute } from '../utils/locale/setHtmlRootLangAttribute';
import { ISO6391LanguageCode, TranslationsObject } from '../utils/locale/types';

type IntlContextType = {
    messages: TranslationsObject;
    setLocale: (locale: ISO6391LanguageCode) => void;
};

const IntlContext = React.createContext<IntlContextType>({
    messages: englishTranslations,
    setLocale: () => {},
});

export const IntlProviderWrapper = ({
    children,
}: PropsWithChildren): React.ReactElement => {
    const currentLocale = getLocaleFromBrowser();

    const setLocale = (locale: ISO6391LanguageCode): void => {
        setHtmlRootLangAttribute(locale);
    };

    useDidMount(() => {
        setHtmlRootLangAttribute(currentLocale);
    });
    const translations = useMemo(
        () => getEscapedTranslations(currentLocale),
        [currentLocale],
    );
    return (
        <IntlContext.Provider
            value={{
                setLocale,
                messages: translations,
            }}
        >
            <IntlProvider
                key={currentLocale}
                locale={currentLocale}
                messages={translations}
                defaultLocale={getLocaleFromBrowser()}
            >
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};
