import { SelectedLocationType } from '../types/dto/issue';
import { createResourceService } from './resourceService';

interface PostIssueParams {
    encryptedSimpleFormId: string;
    title: string;
    description?: string;
    project: string;
    site: string;
    level: string;
    selectedLocationType: SelectedLocationType;
    positionOnMap?: { x: number; y: number };
}

interface IssueResponse {
    _id: string;
}

export const postIssue = createResourceService<PostIssueParams, string>({
    method: 'post',
    getPath: ({ encryptedSimpleFormId }) => `/${encryptedSimpleFormId}/issue`,
    getBody: ({
        title,
        description,
        project,
        site,
        level,
        selectedLocationType,
        positionOnMap,
    }) => ({
        title,
        description,
        project,
        site,
        level,
        selectedLocationType,
        positionOnMap,
    }),
    mapResponse: (response: IssueResponse) => response._id,
});
