import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { postIssue } from '../services/issueService';
import { IssueDto } from '../types/dto/issue';

export const useIssue = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { encryptedSimpleFormId } = useParams();

    const createIssue = async (params: IssueDto): Promise<string> => {
        if (!encryptedSimpleFormId) {
            throw new Error(' no simple form hash detected');
        }

        return await postIssue(await getAccessTokenSilently(), {
            ...params,
            encryptedSimpleFormId,
        });
    };

    return { createIssue };
};
