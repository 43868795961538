import { getUnescapedTranslationObjectByLocale } from './getUnescapedTranslationObjectByLocale';
import { removeEscapingBackslash } from './removeEscapingBackslash';
import { ISO6391LanguageCodeOption, TranslationsObject } from './types';

export function getEscapedTranslations(
    locale: ISO6391LanguageCodeOption,
): TranslationsObject {
    const unescapedTranslationObject =
        getUnescapedTranslationObjectByLocale(locale);
    return removeEscapingBackslash(unescapedTranslationObject);
}
