export enum HOSTS {
    LOCALHOST = 'localhost',
    STAGING = 'form.stage.hustroapp.com',
    PRODUCTION = 'form.hustro.com',
}

export enum ENVIRONMENTS {
    DEVELOPMENT = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export function isDevEnv(): boolean {
    return getEnvironment() === ENVIRONMENTS.DEVELOPMENT;
}
export function getEnvironment(): ENVIRONMENTS {
    const host = window.location.hostname as HOSTS;

    switch (host) {
        case HOSTS.STAGING:
            return ENVIRONMENTS.STAGING;
        case HOSTS.PRODUCTION:
            return ENVIRONMENTS.PRODUCTION;
        default:
            return ENVIRONMENTS.DEVELOPMENT;
    }
}

const stagingSumologgerCollectorUrl =
    'https://collectors.de.sumologic.com/receiver/v1/http/ZaVnC4dhaV2xAKMXRV4tw5zTJvUnp-q7Wix3tFauaYadJ69AtlbqRNLsuLIzrqjNH4NGlH12jTVLwe_klOmjpSSNCfhIGTZFK9iaOVwQzQYvZBRP6eJFGA==';
const productionSumologgerCollectorUrl =
    'https://collectors.de.sumologic.com/receiver/v1/http/ZaVnC4dhaV3YW8h4KDOxL8kJT8OfRbpmeULg8sUPiDe36nbetpvzdg25VXiaHHtnyNS6UUs0_2wlbHsU63ztZVWEfVS04G6S7D7hTRIthduFH5JGr-sPVQ==';

export function getSumologicCollectorUrl() {
    const env = getEnvironment();

    switch (env) {
        case ENVIRONMENTS.STAGING:
            return stagingSumologgerCollectorUrl;
        case ENVIRONMENTS.PRODUCTION:
            return productionSumologgerCollectorUrl;
        default:
            return '';
    }
}
