import axios from 'axios';
import {
    UploadOutDto,
    UploadResult,
    UploadStatus,
} from '../components/fileUploader/types.ts';
import { ObjectIdString } from '../types/common.ts';
import { FileDto } from '../types/dto/file.ts';
import { HashMap } from '../utils/locale/types.ts';
import { ApiClient, createResourceService } from './resourceService.ts';

export const getSignedUrlForUpload = createResourceService<
    {
        projectId: ObjectIdString;
        issueId: ObjectIdString;
        fileInfo: FileDto;
    },
    UploadOutDto
>({
    client: ApiClient.Authorized,
    method: 'post',
    getPath: ({ projectId, issueId }) =>
        `/project/${projectId}/issue/${issueId}/document`,
    getBody: ({ fileInfo }) => ({
        description: fileInfo.description,
        fileName: fileInfo.fileName,
        fileType: fileInfo.fileType,
        title: fileInfo.title,
    }),
});

export const sendUploadResult = createResourceService<
    {
        projectId: ObjectIdString;
        issueId: ObjectIdString;
        documentId: ObjectIdString;
        uploadStatus: UploadStatus;
    },
    string
>({
    client: ApiClient.Authorized,
    method: 'get',
    getPath: ({ projectId, issueId, documentId, uploadStatus }) => {
        const uploadResult =
            uploadStatus === UploadStatus.success
                ? UploadResult.success
                : UploadResult.failed;
        return `/project/${projectId}/issue/${issueId}/document/${documentId}/${uploadResult}`;
    },
});

export const uploadFileToS3 = async (
    url: string,
    formData: FormData,
): Promise<string> => {
    const response = await axios.post(url, formData, {
        headers: { 'x-amz-acl': 'public-read' },
    });
    return response.data;
};

export function formAndUploadFile(
    file: Blob,
    fields: HashMap<string>,
    url: string,
): Promise<string> {
    const formData = new FormData();
    const awsFields = fields;
    Object.keys(awsFields).forEach((key) => {
        formData.append(key, awsFields[key]);
    });

    if (file) {
        formData.append('file', file);
    }

    return uploadFileToS3(url, formData);
}

export enum DocumentTypes {
    file = 'FILE',
}
// export enum SyncStatus {
//     SUCCESS = 'SUCCESS',
//     PENDING = 'PENDING',
//     FAIL = 'FAIL',
//     PENDING_DELETE = 'PENDING_DELETE',
// }
