import { useState, useCallback } from 'react';

export const useError = () => {
    const [error, setError] = useState<Error | null>(null);

    const handleError = useCallback((error: Error) => {
        setError(error);
    }, []);

    return { error, handleError };
};
