import { useParams } from 'react-router-dom';

export type LabelledEntityWithId = { _id: ObjectIdString; label: string };
export type LabelledEntityWithEncryptedId = {
    encryptedId: EncryptedObjectIdString;
    label: string;
};

export type ObjectIdString = string & {
    __value__: never;
};
export type EncryptedObjectIdString = string & {
    __value__: never;
};

export enum UrlParam {
    projectId = 'projectId',
    issueId = 'issueId',
    encryptedSimpleFormId = 'encryptedSimpleFormId',
    encryptedClientId = 'encryptedClientId',
}

function isObjectIdString(value: unknown): value is ObjectIdString {
    if (typeof value !== 'string') {
        return false;
    }

    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(value);
}

function assertObjectIdString(value: unknown): ObjectIdString {
    if (!isObjectIdString(value)) {
        throw new Error(`Invalid ObjectId string: ${value}`);
    }
    return value as ObjectIdString;
}

export const useRequiredObjectIdParam = (
    paramName: UrlParam,
): ObjectIdString => {
    const param = useOptionalObjectIdParam(paramName);

    if (!param) {
        throw new Error(`Required param ${paramName} is missing`);
    }

    return assertObjectIdString(param);
};
export const useOptionalObjectIdParam = (
    paramName: UrlParam,
): ObjectIdString | undefined => {
    const params = useParams();
    const param = params[paramName];

    return param as ObjectIdString | undefined;
};

export const useRequiredEncryptedObjectIdParam = (
    paramName: UrlParam,
): EncryptedObjectIdString => {
    const param = useOptionalEncryptedObjectIdParam(paramName);

    if (!param) {
        throw new Error(`Required param ${paramName} is missing`);
    }

    return param as EncryptedObjectIdString;
};
export const useOptionalEncryptedObjectIdParam = (
    paramName: UrlParam,
): EncryptedObjectIdString | undefined => {
    const params = useParams();
    const param = params[paramName];

    return param as EncryptedObjectIdString | undefined;
};
