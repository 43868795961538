import { EncryptedObjectIdString } from '../types/common';

export function extractEncryptedSimpleFormIdFromQuery() {
    const urlParams = new URLSearchParams(window.location.search);

    const encryptedSimpleFormIdFromQueryparam = urlParams.get('formId');

    return encryptedSimpleFormIdFromQueryparam;
}

export function extractEncryptedSimpleFormIdFromPath() {
    const pathParts = window.location.pathname.split('/');
    return pathParts[1] || null;
}

export function isEncryptedObjectIdString(
    input: unknown,
): input is EncryptedObjectIdString {
    return typeof input === 'string' && input.length === 22;
}

export function setSimpleFormId(
    encryptedSimpleFormId: EncryptedObjectIdString,
) {
    localStorage.setItem('encryptedSimpleFormId', encryptedSimpleFormId);
}
export function getSimpleFormId(): EncryptedObjectIdString | undefined {
    const hash = localStorage.getItem('encryptedSimpleFormId');
    if (isEncryptedObjectIdString(hash)) {
        return hash;
    }
}
