import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { getMe, HustroUser, postPersonalData } from '../services/userService';
import { EncryptedObjectIdString, ObjectIdString } from '../types/common';

export const useMe = () => {
    const [me, setMe] = useState<HustroUser | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let isMounted = true;

        const fetchUser = async () => {
            try {
                setLoading(true);
                const token = await getAccessTokenSilently();
                const data = await getMe(token, {});

                if (isMounted) {
                    console.log('Setting user data:', data);
                    setMe(data);
                    localStorage.setItem('id', data._id);
                }
            } catch (err) {
                console.error('Error fetching user:', err);
                setError(
                    err instanceof Error ? err : new Error('Unknown error'),
                );
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchUser();

        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    const addOrUpdatePersonalData = useCallback(
        async (
            encryptedClientId: EncryptedObjectIdString,
            label: string,
            phone: string,
            userId: ObjectIdString,
        ) => {
            try {
                const token = await getAccessTokenSilently();
                await postPersonalData(token, {
                    encryptedClientId,
                    label,
                    userId,
                    phone,
                });

                const updatedUserData = await getMe(token, {});
                setMe(updatedUserData);
            } catch (err) {
                console.error('Error updating personal data:', err);
                throw err;
            }
        },
        [getAccessTokenSilently],
    );

    return { me, loading, error, addOrUpdatePersonalData };
};
