import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { LevelDto } from '../types/dto/level';
import { buildPath, createResourceService } from './resourceService';

export const getLevels = createResourceService<
    {
        encryptedSimpleFormId: EncryptedObjectIdString;
        projectId: ObjectIdString;
        siteId: ObjectIdString;
    },
    LevelDto[]
>({
    getPath: ({ encryptedSimpleFormId, projectId, siteId }) =>
        buildPath(
            encryptedSimpleFormId,
            'project',
            projectId,
            'site',
            siteId,
            'level',
        ),
});
