import {
    Log,
    LoggerOptions,
    CreateStandardLogFields,
    CreateErrorLogFields,
} from './types';

export class SumoLogicLogger {
    private readonly collectorUrl: string;
    private queue: Log[];
    private isProcessing: boolean;
    private readonly batchSize: number;
    private readonly retryDelay: number;

    constructor(collectorUrl: string, options: LoggerOptions = {}) {
        this.collectorUrl = collectorUrl;
        this.queue = [];
        this.isProcessing = false;
        this.batchSize = options.batchSize ?? 10;
        this.retryDelay = options.retryDelay ?? 5000;
    }

    private async sendLogs(logs: Log[]): Promise<boolean> {
        try {
            const response = await fetch(this.collectorUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(logs),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return true;
        } catch (error) {
            console.error('Error sending logs to Sumologic:', error);
            return false;
        }
    }

    private queueLog(
        log: CreateStandardLogFields | CreateErrorLogFields,
    ): void {
        const fullLog: Log = {
            ...log,
            timestamp: new Date().toISOString(),
            environment: process.env.NODE_ENV ?? 'development',
        };

        this.queue.push(fullLog);

        if (!this.isProcessing) {
            void this.processQueue();
        }
    }

    private async processQueue(): Promise<void> {
        if (this.queue.length === 0) {
            this.isProcessing = false;
            return;
        }

        this.isProcessing = true;
        const batch = this.queue.splice(0, this.batchSize);

        const success = await this.sendLogs(batch);
        if (!success) {
            this.queue.unshift(...batch);
            await new Promise((resolve) =>
                setTimeout(resolve, this.retryDelay),
            );
        }

        void this.processQueue();
    }

    public info(message: string, metadata: Record<string, unknown> = {}): void {
        const log: CreateStandardLogFields = {
            level: 'INFO',
            message,
            metadata,
        };
        this.queueLog(log);
    }

    public error(
        message: string,
        error: Error,
        metadata: Record<string, unknown> = {},
    ): void {
        const log: CreateErrorLogFields = {
            level: 'ERROR',
            message,
            error: {
                message: error.message,
                stack: error.stack,
                name: error.name,
            },
            metadata,
        };
        this.queueLog(log);
    }

    public warn(message: string, metadata: Record<string, unknown> = {}): void {
        const log: CreateStandardLogFields = {
            level: 'WARN',
            message,
            metadata,
        };
        this.queueLog(log);
    }

    public debug(
        message: string,
        metadata: Record<string, unknown> = {},
    ): void {
        if (process.env.NODE_ENV !== 'production') {
            const log: CreateStandardLogFields = {
                level: 'DEBUG',
                message,
                metadata,
            };
            this.queueLog(log);
        }
    }
}
