import * as Sentry from '@sentry/react';
import { getEnvironment, isDevEnv } from '../utils/environment';

export function initSentry() {
    if (!isDevEnv()) {
        Sentry.init(sentryConfig);
    }
}
const sentryConfig = {
    dsn: 'https://898daa460f4f9e165490588b70f0ccbf@o455099.ingest.us.sentry.io/4508257454391296',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: getEnvironment(),
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/app\.hustro\.com\/api/,
        /^https:\/\/stage\.o\.hustroapp\.com\/api/,
    ],
    beforeSend(event: Sentry.ErrorEvent) {
        const userEmail = localStorage.getItem('email');
        const userId = localStorage.getItem('id');
        if (userEmail || userId) {
            event.contexts = event.contexts || {};
            event.contexts.user = {
                id: userEmail,
                email: userEmail,
            };
        }
        return event;
    },

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
