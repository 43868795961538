import React, { useEffect, useState } from 'react';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { useSignedUrls } from '../../hooks/useSignedUrl';
import { MapTransformWrapperProps as MapWWrapperProps } from './mapTransformWrapper';
import { Pin } from './pin';
import { useClickWithoutDrag } from './useClickWithoutDrag';
import { useHandlePinPosition } from './useHandlePinPosition';
function isInRange(value: number, bottom: number, top: number): boolean {
    return value <= top && value >= bottom;
}

function isInImageBounds(x: number, y: number): boolean {
    return isInRange(x, 0, 1) && isInRange(y, 0, 1);
}

type MapProps = MapWWrapperProps & {
    containerRef: React.RefObject<HTMLDivElement>;
    scale: number;
};

export const MapWithPin: React.FC<MapProps> = ({
    levelId,
    documentationId,
    versionId,
    containerRef,
    scale,
    pinPositionX,
    pinPositionY,
    setPinPosition,
}) => {
    const { fetchSignedUrl } = useSignedUrls();
    const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined);

    const imageRef = React.useRef<HTMLImageElement | null>(null);
    useEffect(() => {
        async function fetchData() {
            console.log(signedUrl);
            const url = await fetchSignedUrl(
                levelId,
                documentationId,
                versionId,
            );
            setSignedUrl(url);
        }
        fetchData();
    }, [fetchSignedUrl, documentationId, versionId, signedUrl, levelId]);

    const handlePinPosition = useHandlePinPosition({
        imageRef,
        containerRef,
        setPinPosition,
        isInImageBounds,
    });

    const clickHandlers = useClickWithoutDrag({ onClick: handlePinPosition });

    return (
        <TransformComponent wrapperStyle={{ margin: 'auto' }}>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                }}
                {...clickHandlers}
            >
                <img
                    src={signedUrl}
                    crossOrigin="use-credentials"
                    ref={imageRef}
                    alt="Map"
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                    }}
                />
                <Pin x={pinPositionX} y={pinPositionY} scale={scale} />
            </div>
        </TransformComponent>
    );
};
