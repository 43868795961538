import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeColors = {
    yellowMain: '#FBCC30',
    green: '#4CAF50',
    blue: {
        main: '#283593',
        light: '#5f5fc4',
        dark: '#001064',
        contrastText: '#ffffff',
        50: '#e8eaf6',
        100: '#c5cae9',
        200: '#9fa8da',
        300: '#7986cb',
        400: '#5c6bc0',
        500: '#283593', // main
        600: '#242f82',
        700: '#1a237e',
        800: '#151b6d',
        900: '#0d1350',
    },
    black: '#000000',
};

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: themeColors.yellowMain,
            contrastText: themeColors.black,
        },
        secondary: themeColors.blue,
    },
    components: {
        //     MuiOutlinedInput: {
        //         styleOverrides: {
        //             root: {
        //                 '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
        //                     {
        //                         borderColor: '#283593',
        //                     },
        //                 '&:not(.Mui-disabled):hover': {
        //                     '.MuiInputLabel-root': {
        //                         color: '#283593',
        //                     },
        //                 },
        //             },
        //         },
        //     },
        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             maxWidth: '500px',
        //             // '&:not(.Mui-disabled):hover': {
        //             //     borderColor: '#283593',
        //             //     '& .MuiButton-label': {
        //             //         color: '#283593',
        //             //     },
        //             // },
        //         },
        //     },
        // },
    },
};

export const theme = createTheme(themeOptions);
