import { useContext } from 'react';
import { LoggerContext } from '../utils/logger/loggerContext';
import { SumoLogicLogger } from '../utils/logger/sumologicLogger';

export const useLogger = (): SumoLogicLogger => {
    const context = useContext(LoggerContext);
    if (!context) {
        throw new Error('useLogger must be used within a LoggerProvider');
    }
    return context.logger;
};
