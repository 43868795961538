import { useAuth0 } from '@auth0/auth0-react';
import { UploadStatus } from '../components/fileUploader/types.ts';
import {
    formAndUploadFile,
    getSignedUrlForUpload,
    sendUploadResult,
} from '../services/fileService.ts';
import { ObjectIdString } from '../types/common.ts';
export type FileWithIdAndStatus = File & {
    uploadStatus: UploadStatus;
    _id: ObjectIdString | null;
};

function fileToAfterUploadFile(
    file: File,
    fileId: ObjectIdString,
    uploadStatus: UploadStatus,
): FileWithIdAndStatus {
    // we need to keep the object reference for URL.createObjectURL method
    const fileWithIdAndStatus = file as FileWithIdAndStatus;

    fileWithIdAndStatus.uploadStatus = uploadStatus;
    fileWithIdAndStatus._id = fileId;
    return fileWithIdAndStatus;
}

export const useFile = () => {
    const { getAccessTokenSilently } = useAuth0();

    const reader = new FileReader();

    const uploadFile = async (
        file: File,
        projectId: ObjectIdString,
        issueId: ObjectIdString,
    ): Promise<FileWithIdAndStatus> => {
        const fileDto = {
            fileName: file.name,
            fileType: file.type,
            description: '',
            title: '',
        };

        const {
            signedRequest: { url: signedUrl, fields: awsFields },
            document: { _id: fileId },
        } = await getSignedUrlForUpload(await getAccessTokenSilently(), {
            projectId,
            issueId,
            fileInfo: fileDto,
        });

        return new Promise<FileWithIdAndStatus>((resolve, reject) => {
            let uploadStatus = UploadStatus.pending;

            reader.onload = async () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const blob = new Blob([arrayBuffer], { type: file.type });

                try {
                    await formAndUploadFile(blob, awsFields, signedUrl);
                    uploadStatus = UploadStatus.success;
                } catch (e) {
                    console.error(e);
                    uploadStatus = UploadStatus.failed;
                }

                try {
                    await sendUploadResult(await getAccessTokenSilently(), {
                        projectId,
                        issueId,
                        documentId: fileId,
                        uploadStatus,
                    });
                } catch (e) {
                    console.error('Failed to send upload result:', e);
                    uploadStatus = UploadStatus.failed;
                    return reject(e);
                }

                resolve(fileToAfterUploadFile(file, fileId, uploadStatus));
            };

            reader.onerror = (error) => {
                console.error('FileReader error:', error);
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    return { uploadFile };
};
