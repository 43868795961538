import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
} from '@mui/material';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string | React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string;
    cancelText?: string;
    confirmButtonProps?: Partial<React.ComponentProps<typeof Button>>;
    cancelButtonProps?: Partial<React.ComponentProps<typeof Button>>;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    title,
    message,
    onConfirm,
    onCancel,
    confirmText = 'Kontynuuj',
    cancelText = 'Anuluj',
    confirmButtonProps = { color: 'primary', variant: 'contained' },
    cancelButtonProps = { color: 'primary' },
    maxWidth = 'sm',
}) => {
    return (
        <Dialog open={open} onClose={onCancel} maxWidth={maxWidth} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {typeof message === 'string' ? (
                    <DialogContentText>{message}</DialogContentText>
                ) : (
                    message
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} {...cancelButtonProps}>
                    {cancelText}
                </Button>
                <Button onClick={onConfirm} {...confirmButtonProps}>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
