import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { ApiClient, createResourceService } from './resourceService';

export type HustroUser = {
    email: string;
    _id: ObjectIdString;
    basePermissions?: { role?: string };
    personalData: {
        label: string;
        phone: string;
        encryptedClientId: EncryptedObjectIdString;
        clientId: ObjectIdString;
    }[];
};

interface PostPersonalDataParams {
    userId: ObjectIdString;
    encryptedClientId: EncryptedObjectIdString;
    label: string;
    phone: string;
}

interface SignUpParams {
    // puste, bo nie przyjmuje parametrów poza tokenem
}

interface GetMeParams {
    // puste, bo nie przyjmuje parametrów poza tokenem
}

export const getMe = createResourceService<GetMeParams, HustroUser>({
    getPath: () => '/user/me',
    client: ApiClient.Authorized,
    onError: async (error, token) => {
        if (error?.response?.status === 401) {
            // Próba rejestracji nowego użytkownika
            await signUp(token, {});

            // Ponowne pobranie danych użytkownika, tym razem używając unauthorizedApiClient
            return getMeAuthorized(token, {});
        }

        console.error('Błąd podczas pobierania danych użytkownika:', error);
        throw new Error('Nie udało się pobrać danych użytkownika');
    },
});
export const getMeAuthorized = createResourceService<GetMeParams, HustroUser>({
    getPath: () => '/user/me',
    client: ApiClient.Authorized,
});
export const postPersonalData = createResourceService<
    PostPersonalDataParams,
    HustroUser
>({
    method: 'put',
    getPath: ({ userId, encryptedClientId }) =>
        `/user/${userId}/personalData/client/${encryptedClientId}`,
    getBody: ({ encryptedClientId, label, phone }) => ({
        encryptedClientId: encryptedClientId,
        label,
        phone,
    }),
    client: ApiClient.Authorized,
});

export const signUp = createResourceService<SignUpParams, void>({
    method: 'post',
    getPath: () => '/signup',
    getBody: () => ({}),
    client: ApiClient.Unauthorized,
});
