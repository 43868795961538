import { useAuth0 } from '@auth0/auth0-react';
import { Box, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import fullLogo from '../../assets/fullLogo.png';
import { useLogger } from '../../hooks/useLogger';
import { AuthSkeleton } from '../skeleton';
import { EmailCodeLoginButton } from './emailCodeLoginButton';
import { GoogleLoginButton } from './googleLoginButton';
import { HustroContinueButton } from './hustroContinueButton';
import { HustroLoginButton } from './hustroLoginButton';
const buttonSx = {
    margin: 'auto',
    minWidth: {
        xs: '200px',
        sm: '400px',
    },
};

export interface LoginButtonProps {
    sx?: SxProps;
    onClick?: () => void;
}
export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
    const {
        loginWithRedirect,
        getAccessTokenSilently,
        getIdTokenClaims,
        user,
        isAuthenticated,
        isLoading,
    } = useAuth0();
    const intl = useIntl();
    const logger = useLogger();
    const [
        isUserAuthenticatedWithHustroApp,
        setIsUserAuthenticatedWithHustroApp,
    ] = useState(false);
    useEffect(() => {
        const getToken = async () => {
            const token = await getAccessTokenSilently().catch(async (e) => {
                let errorDescriptionFromUrl;
                let errorMessageFromUrl;
                const timestamp = Date.now();
                if (window.location.search.includes('error')) {
                    const urlParams = new URLSearchParams(
                        window.location.search,
                    );
                    errorDescriptionFromUrl =
                        urlParams.get('error_description');
                    errorMessageFromUrl = urlParams.get('error');
                    logger.error(
                        'Error caught by AuthWrapper',
                        {
                            message: errorMessageFromUrl || '',
                            name: 'Unauthorized',
                        },
                        {
                            timestamp,
                            description: errorDescriptionFromUrl,
                        },
                    );
                }

                if (e?.error === 'login_required') {
                    return;
                } else if (e.error === 'consent_required') {
                    setIsUserAuthenticatedWithHustroApp(true);
                    console.log('moglby byc zalogowany');
                    console.log(await getIdTokenClaims());
                    console.log(user);
                } else {
                    logger.error('Error getting token', e);
                }
            });
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('email', user?.email || ' ');
            }
        };

        getToken();
    }, [
        getAccessTokenSilently,
        loginWithRedirect,
        user,
        getIdTokenClaims,
        isAuthenticated,
        logger,
    ]);

    if (isLoading) {
        return <AuthSkeleton sx={{ mt: 6, mx: 'auto' }} />;
    }
    if (!isAuthenticated) {
        return (
            <Box
                sx={{ mt: 6, mx: 4 }}
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <img
                    style={{ maxWidth: '60%', margin: 'auto' }}
                    src={fullLogo}
                />
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="h4">
                    {intl.formatMessage({ id: 'login_page_welcome_message' })}
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{ mt: 4 }}
                >
                    {isUserAuthenticatedWithHustroApp && (
                        <HustroContinueButton sx={buttonSx} />
                    )}
                    <EmailCodeLoginButton sx={buttonSx} />
                    <GoogleLoginButton sx={buttonSx} />
                    {!isUserAuthenticatedWithHustroApp && (
                        <HustroLoginButton sx={buttonSx} />
                    )}
                </Box>
            </Box>
        );
    }
    return children;
};
