import { getLevels } from '../services/levelService';
import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { LevelDto } from '../types/dto/level';
import { useResource } from './useResource';

const levelsBySiteMap = new Map<string, LevelDto[]>();

export const useLevels = () => {
    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
            projectId: ObjectIdString;
            siteId: ObjectIdString;
        },
        LevelDto[]
    >({
        fetchFn: getLevels,
        cacheMap: levelsBySiteMap,
        getCacheKey: ({ siteId }) => siteId,
    });

    return { fetchLevels: fetchResource };
};
