import { EncryptedObjectIdString, ObjectIdString } from '../types/common';
import { SiteDto } from '../types/dto/site';
import { buildPath, createResourceService } from './resourceService';

export const getSites = createResourceService<
    {
        encryptedSimpleFormId: EncryptedObjectIdString;
        projectId: ObjectIdString;
    },
    SiteDto[]
>({
    getPath: ({ encryptedSimpleFormId, projectId }) =>
        buildPath(encryptedSimpleFormId, 'project', projectId, 'site'),
});
