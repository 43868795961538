import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { ObjectIdString } from '../types/common';

interface FetchResourceConfig<TParams, TResponse> {
    fetchFn: (token: string, params: TParams) => Promise<TResponse>;
    cacheMap: Map<string, TResponse>;
    getCacheKey: (params: TParams) => ObjectIdString;
}

export const useResource = <TParams, TResponse>({
    fetchFn,
    cacheMap,
    getCacheKey,
}: FetchResourceConfig<TParams, TResponse>) => {
    const { getAccessTokenSilently } = useAuth0();

    const fetchResource = useCallback(
        async (params: TParams): Promise<TResponse> => {
            try {
                const cacheKey = getCacheKey(params);
                const cachedResult = cacheMap.get(cacheKey);

                if (cachedResult) {
                    // console.log(
                    //     `Returning cached data for key ${cacheKey} with result:`,
                    // );
                    // console.log(cachedResult);
                    // console.log('params');
                    // console.log(params);
                    return cachedResult;
                }

                const token = await getAccessTokenSilently();
                const data = await fetchFn(token, params);
                cacheMap.set(cacheKey, data);
                return data;
            } catch (error) {
                if (error instanceof Error) {
                    throw error;
                }
                throw new Error(
                    error instanceof Object
                        ? JSON.stringify(error)
                        : String(error),
                );
            }
        },
        [getAccessTokenSilently, fetchFn, cacheMap, getCacheKey],
    );

    return { fetchResource };
};
